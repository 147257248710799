import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { useReducer } from 'react';
import { Stepper, StepState, Button, IconArrowLeft, IconArrowRight, Link } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const StepperExample = () => {
  const commonReducer = stepsTotal => (state, action) => {
    switch (action.type) {
      case 'completeStep':
        {
          const activeStepIndex = action.payload === stepsTotal - 1 ? stepsTotal - 1 : action.payload + 1;
          return {
            activeStepIndex,
            steps: state.steps.map((step, index) => {
              if (index === action.payload && index !== stepsTotal - 1) {
                // current one but not last one
                return {
                  state: StepState.completed,
                  label: step.label
                };
              }
              if (index === action.payload + 1) {
                // next one
                return {
                  state: StepState.available,
                  label: step.label
                };
              }
              return step;
            })
          };
        }
      case 'setActive':
        {
          return {
            activeStepIndex: action.payload,
            steps: state.steps.map((step, index) => {
              if (index === action.payload) {
                return {
                  state: StepState.available,
                  label: step.label
                };
              }
              return step;
            })
          };
        }
      default:
        throw new Error();
    }
  };
  const reducer = commonReducer(5);
  const initialState = {
    activeStepIndex: 0,
    steps: [{
      label: 'Step 1',
      state: StepState.available
    }, {
      label: 'Step 2',
      state: StepState.disabled
    }, {
      label: 'Step 3',
      state: StepState.disabled
    }, {
      label: 'Step 4 - longer text',
      state: StepState.disabled
    }, {
      label: 'Step 5',
      state: StepState.disabled
    }]
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const lastStep = state.activeStepIndex === state.steps.length - 1;
  return <div>
      <Stepper steps={state.steps} language="en" selectedStep={state.activeStepIndex} onStepClick={(event, stepIndex) => dispatch({
      type: 'setActive',
      payload: stepIndex
    })} mdxType="Stepper" />
      <div style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      gap: '24px'
    }}>
        <Button disabled={state.activeStepIndex === 0} variant="secondary" onClick={() => dispatch({
        type: 'setActive',
        payload: state.activeStepIndex - 1
      })} style={{
        height: 'fit-content',
        width: 'fit-content'
      }} iconLeft={<IconArrowLeft mdxType="IconArrowLeft" />} mdxType="Button">
          Previous
        </Button>
        <Button variant={lastStep ? 'primary' : 'secondary'} onClick={() => dispatch({
        type: 'completeStep',
        payload: state.activeStepIndex
      })} style={{
        height: 'fit-content',
        width: 'fit-content'
      }} iconRight={lastStep ? undefined : <IconArrowRight mdxType="IconArrowRight" />} mdxType="Button">
          {lastStep ? 'Send' : 'Next'}
        </Button>
      </div>
    </div>;
};
export const SmallStepperExample = () => {
  const commonReducer = stepsTotal => (state, action) => {
    switch (action.type) {
      case 'completeStep':
        {
          const activeStepIndex = action.payload === stepsTotal - 1 ? stepsTotal - 1 : action.payload + 1;
          return {
            activeStepIndex,
            steps: state.steps.map((step, index) => {
              if (index === action.payload && index !== stepsTotal - 1) {
                // current one but not last one
                return {
                  state: StepState.completed,
                  label: step.label
                };
              }
              if (index === action.payload + 1) {
                // next one
                return {
                  state: StepState.available,
                  label: step.label
                };
              }
              return step;
            })
          };
        }
      case 'setActive':
        {
          return {
            activeStepIndex: action.payload,
            steps: state.steps.map((step, index) => {
              if (index === action.payload) {
                return {
                  state: StepState.available,
                  label: step.label
                };
              }
              return step;
            })
          };
        }
      default:
        throw new Error();
    }
  };
  const reducer = commonReducer(5);
  const initialState = {
    activeStepIndex: 0,
    steps: [{
      label: 'Step 1',
      state: StepState.available
    }, {
      label: 'Step 2',
      state: StepState.disabled
    }, {
      label: 'Step 3',
      state: StepState.disabled
    }, {
      label: 'Step 4 - longer text',
      state: StepState.disabled
    }, {
      label: 'Step 5',
      state: StepState.disabled
    }]
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const lastStep = state.activeStepIndex === state.steps.length - 1;
  return <div style={{
    maxWidth: '480px'
  }}>
      <Stepper steps={state.steps} language="en" small selectedStep={state.activeStepIndex} onStepClick={(event, stepIndex) => dispatch({
      type: 'setActive',
      payload: stepIndex
    })} mdxType="Stepper" />
      <div style={{
      height: '80px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      gap: '24px'
    }}>
        <Button disabled={state.activeStepIndex === 0} variant="secondary" onClick={() => dispatch({
        type: 'setActive',
        payload: state.activeStepIndex - 1
      })} style={{
        height: 'fit-content',
        width: 'fit-content'
      }} iconLeft={<IconArrowLeft mdxType="IconArrowLeft" />} mdxType="Button">
          Previous
        </Button>
        <Button variant={lastStep ? 'primary' : 'secondary'} onClick={() => dispatch({
        type: 'completeStep',
        payload: state.activeStepIndex
      })} style={{
        height: 'fit-content',
        width: 'fit-content'
      }} iconRight={lastStep ? undefined : <IconArrowRight mdxType="IconArrowRight" />} mdxType="Button">
          {lastStep ? 'Send' : 'Next'}
        </Button>
      </div>
    </div>;
};
export const HeadingStepperExample = () => {
  const commonReducer = stepsTotal => (state, action) => {
    switch (action.type) {
      case 'completeStep':
        {
          const activeStepIndex = action.payload === stepsTotal - 1 ? stepsTotal - 1 : action.payload + 1;
          return {
            activeStepIndex,
            steps: state.steps.map((step, index) => {
              if (index === action.payload && index !== stepsTotal - 1) {
                // current one but not last one
                return {
                  state: StepState.completed,
                  label: step.label
                };
              }
              if (index === action.payload + 1) {
                // next one
                return {
                  state: StepState.available,
                  label: step.label
                };
              }
              return step;
            })
          };
        }
      case 'setActive':
        {
          return {
            activeStepIndex: action.payload,
            steps: state.steps.map((step, index) => {
              if (index === action.payload) {
                return {
                  state: StepState.available,
                  label: step.label
                };
              }
              return step;
            })
          };
        }
      default:
        throw new Error();
    }
  };
  const reducer = commonReducer(5);
  const initialState = {
    activeStepIndex: 0,
    steps: [{
      label: 'Step 1',
      state: StepState.available
    }, {
      label: 'Step 2',
      state: StepState.disabled
    }, {
      label: 'Step 3',
      state: StepState.disabled
    }, {
      label: 'Step 4 - longer text',
      state: StepState.disabled
    }, {
      label: 'Step 5',
      state: StepState.disabled
    }]
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const lastStep = state.activeStepIndex === state.steps.length - 1;
  return <div>
      <Stepper steps={state.steps} language="en" stepHeading selectedStep={state.activeStepIndex} onStepClick={(event, stepIndex) => dispatch({
      type: 'setActive',
      payload: stepIndex
    })} mdxType="Stepper" />
      <div style={{
      height: '80px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      gap: '24px'
    }}>
        <Button disabled={state.activeStepIndex === 0} variant="secondary" onClick={() => dispatch({
        type: 'setActive',
        payload: state.activeStepIndex - 1
      })} style={{
        height: 'fit-content',
        width: 'fit-content'
      }} iconLeft={<IconArrowLeft mdxType="IconArrowLeft" />} mdxType="Button">
          Previous
        </Button>
        <Button variant={lastStep ? 'primary' : 'secondary'} onClick={() => dispatch({
        type: 'completeStep',
        payload: state.activeStepIndex
      })} style={{
        height: 'fit-content',
        width: 'fit-content'
      }} iconRight={lastStep ? undefined : <IconArrowRight mdxType="IconArrowRight" />} mdxType="Button">
          {lastStep ? 'Send' : 'Next'}
        </Button>
      </div>
    </div>;
};
export const OverflowStepperExample = () => {
  const commonReducer = stepsTotal => (state, action) => {
    switch (action.type) {
      case 'completeStep':
        {
          const activeStepIndex = action.payload === stepsTotal - 1 ? stepsTotal - 1 : action.payload + 1;
          return {
            activeStepIndex,
            steps: state.steps.map((step, index) => {
              if (index === action.payload && index !== stepsTotal - 1) {
                // current one but not last one
                return {
                  state: StepState.completed,
                  label: step.label
                };
              }
              if (index === action.payload + 1) {
                // next one
                return {
                  state: StepState.available,
                  label: step.label
                };
              }
              return step;
            })
          };
        }
      case 'setActive':
        {
          return {
            activeStepIndex: action.payload,
            steps: state.steps.map((step, index) => {
              if (index === action.payload) {
                return {
                  state: StepState.available,
                  label: step.label
                };
              }
              return step;
            })
          };
        }
      default:
        throw new Error();
    }
  };
  const reducer = commonReducer(5);
  const initialState = {
    activeStepIndex: 0,
    steps: [{
      label: 'Step 1',
      state: StepState.available
    }, {
      label: 'Step 2',
      state: StepState.disabled
    }, {
      label: 'Step 3',
      state: StepState.disabled
    }, {
      label: 'Step 4 - longer text',
      state: StepState.disabled
    }, {
      label: 'Step 5',
      state: StepState.disabled
    }]
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const lastStep = state.activeStepIndex === state.steps.length - 1;
  return <div style={{
    maxWidth: '400px'
  }}>
      <Stepper steps={state.steps} language="en" selectedStep={state.activeStepIndex} onStepClick={(event, stepIndex) => dispatch({
      type: 'setActive',
      payload: stepIndex
    })} mdxType="Stepper" />
      <div style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      gap: '24px'
    }}>
        <Button disabled={state.activeStepIndex === 0} variant="secondary" onClick={() => dispatch({
        type: 'setActive',
        payload: state.activeStepIndex - 1
      })} style={{
        height: 'fit-content',
        width: 'fit-content'
      }} iconLeft={<IconArrowLeft mdxType="IconArrowLeft" />} mdxType="Button">
          Previous
        </Button>
        <Button variant={lastStep ? 'primary' : 'secondary'} onClick={() => dispatch({
        type: 'completeStep',
        payload: state.activeStepIndex
      })} style={{
        height: 'fit-content',
        width: 'fit-content'
      }} iconRight={lastStep ? undefined : <IconArrowRight mdxType="IconArrowRight" />} mdxType="Button">
          {lastStep ? 'Send' : 'Next'}
        </Button>
      </div>
    </div>;
};
export const _frontmatter = {
  "slug": "/components/stepper",
  "title": "Stepper",
  "navTitle": "Stepper"
};
const layoutProps = {
  StepperExample,
  SmallStepperExample,
  HeadingStepperExample,
  OverflowStepperExample,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <StepperExample mdxType="StepperExample" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Each form step must be given a clear and descriptive label.`}
        <ul parentName="li">
          <li parentName="ul">{`The label is required even if it is visually hidden on smaller screen sizes.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`It is recommended that the form steps must be filled in order.`}
        <ul parentName="li">
          <li parentName="ul">{`This means that steps are disabled until their previous step is completed successfully.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Avoid using a stepper in short forms. One step should have at least three (3) form components inside it.`}</li>
      <li parentName="ul">{`Use `}<a parentName="li" {...{
          "href": "#small"
        }}>{`small variant`}</a>{` on small screens or spaces.`}
        <ul parentName="li">
          <li parentName="ul">{`You can use the default variant on small screens if the amount of steps is small (2-4 steps).`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Do not span the stepper into multiple rows. If you are running out of space, consider using `}<a parentName="li" {...{
          "href": "#small"
        }}>{`the small variant`}</a>{` or alter the spacing between steps.`}
        <ul parentName="li">
          <li parentName="ul">{`HDS stepper also supports scrolling when it overflows outside the container. Generally, this should be the last resort option if it is not possible to fit the stepper on screen with other methods.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You can find an example of creating a complete form with the stepper in `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" size="M" href="/storybook/react/?path=/docs/components-stepper--simple-form-example#simple-form-example" mdxType="Link">{`the HDS React Storybook`}</Link>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The default stepper variant includes visible step labels. The stepper can be navigated by pressing the steps themselves or controlled via external button elements.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
   <StepperExample mdxType="StepperExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "small",
      "style": {
        "position": "relative"
      }
    }}>{`Small`}<a parentName="h4" {...{
        "href": "#small",
        "aria-label": "small permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The small stepper variant omits the visible step labels. This variant of the stepper suits better for smaller screens or tight spaces. Steps are still interactable even though the labels are visually hidden.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <SmallStepperExample mdxType="SmallStepperExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-step-heading",
      "style": {
        "position": "relative"
      }
    }}>{`With step heading`}<a parentName="h4" {...{
        "href": "#with-step-heading",
        "aria-label": "with step heading permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The stepper can automatically render the step heading if the property `}<inlineCode parentName="p">{`stepHeading`}</inlineCode>{` is enabled. The step heading will include the current step number, the total amount of steps, and the step label. `}</p>
    <p>{`You can control the heading level of the generated step heading via the `}<inlineCode parentName="p">{`stepHeadingAriaLevel`}</inlineCode>{` property. The heading is automatically localised if you change the component language with the `}<inlineCode parentName="p">{`language`}</inlineCode>{` property.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <HeadingStepperExample mdxType="HeadingStepperExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "overflow",
      "style": {
        "position": "relative"
      }
    }}>{`Overflow`}<a parentName="h4" {...{
        "href": "#overflow",
        "aria-label": "overflow permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The stepper reacts accordingly when it overflows outside of the container. The user can scroll the stepper view or use left and right angle icons to browse the stepper.`}</p>
    <p>{`This variant should only be the last resort option if it is not possible to fit the stepper on screen with other methods. Prefer the `}<inlineCode parentName="p">{`small`}</inlineCode>{` variant or shorter labels as a solution before resorting to overflow.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <OverflowStepperExample mdxType="OverflowStepperExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "step-states",
      "style": {
        "position": "relative"
      }
    }}>{`Step states`}<a parentName="h4" {...{
        "href": "#step-states",
        "aria-label": "step states permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The HDS stepper offers multiple predefined states for steps to be used. `}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`completed`}</inlineCode>{` state for completed steps`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`available`}</inlineCode>{` state for steps that are available but not completed yet`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`attention`}</inlineCode>{` state for steps that require the user's attention (e.g. the step has been edited by another user)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`paused`}</inlineCode>{` state for steps that require the user's attention (e.g. waiting for a review from officials)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`disabled`}</inlineCode>{` state for steps that are not available yet (e.g. require a previous step to be completed first)`}</li>
    </ul>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Stepper language="en" onStepClick={function noRefCheck() {}} selectedStep={1} steps={[{
        label: 'Completed',
        state: StepState.completed
      }, {
        label: 'Current',
        state: StepState.available
      }, {
        label: 'Available',
        state: StepState.available
      }, {
        label: 'Needs attention',
        state: StepState.attention
      }, {
        label: 'Paused',
        state: StepState.paused
      }, {
        label: 'Disabled',
        state: StepState.disabled
      }]} mdxType="Stepper" />  
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      